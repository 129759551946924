import {
	ApiOfferList,
	BaseFilter,
	Nights,
	NumericDictionary,
	OfferDateRange,
	OfferFilter,
	OfferList,
	OfferListHeader,
	OfferListParams,
	ParamsDateRange,
	Selected,
} from '@/_store/offers/offers.types';
import { arrayToObject } from '@/_utils/arrays/array-to-object';
import { convertObjectValuesToStrings } from '@/_utils/strings/convert-object-values-to-strings';
import { createApiURL } from '@/_utils/urls/create-api-url';
import { Params } from '@angular/router';
import { stringToArray } from '@/_utils/strings/string-to-array';
import { TopSearchHeaderConfig } from '@/new-top-search/top-search.config';
import {
	MultiChoiceModes,
	OfferListHeaderItemMode,
	TopSearchDropdownItemMode,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { formatOskarDate } from '@/_utils/datetime/format-oskar-date';

// This is added temporarily until the backend returns less data
export const convertToOfferList = (apiOfferList: ApiOfferList[]): NumericDictionary<OfferList> => {
	const offerListArr: OfferList[] = apiOfferList.map((offer) => {
		return {
			board: { board: offer.board.board, name: offer.board.name },
			catalogPrice: offer.catalogPrice,
			fullPrice: offer.fullPrice,
			promotion: offer.promotion,
			room: {
				roomType: offer.room.roomType,
				term: {
					dateRange: offer.room.term.dateRange,
					nightsStay: offer.room.term.nightsStay,
					product: {
						conveniences: offer.room.term.product.conveniences,
						id: offer.room.term.product.id,
						locations: offer.room.term.product.locations,
						multimedias: offer.room.term.product.multimedias,
						name: offer.room.term.product.name,
					},
					promotion: offer.room.term.promotion,
					status: offer.room.term.status,
				},
				adults: offer.room.adults,
				children: offer.room.children,
			},
			stop: {
				departureDatetime: offer.stop.departureDatetime,
				name: offer.stop.name,
				returnDatetime: offer.stop.returnDatetime,
				stop: offer.stop.stop,
			},
			highlightLastSeats: offer.highlightLastSeats,
		};
	});

	return arrayToObject(offerListArr);
};

export const createOfferListApiParams = (selected: Selected): string => {
	const { lower, upper } = selected.offersDateRange;
	const { min, max } = selected.nights;

	const dateRangeString: ParamsDateRange = lower && upper ? `${lower},${upper}` : '';

	const paramsObj: OfferListParams = {
		adults: selected.adults,
		children: selected.children,
		boards: selected.boards,
		conveniences: selected.conveniences,
		kinds: selected.kinds,
		locations: selected.locations,
		stops: selected.ownTransport ? [] : selected.stops,
		tags: selected.tags,
		nights_min: min,
		nights_max: max,
		date_range: dateRangeString,
		own_transport: selected.ownTransport,
		product_name: selected.search,
		status: selected.status,
	};

	const filteredObject: Record<string, string> = Object.fromEntries(
		Object.entries(convertObjectValuesToStrings(paramsObj)).filter(([, value]) => value !== ''),
	);

	return createApiURL(filteredObject);
};

export const findParticipantValue = (
	filterOptions: OfferFilter,
	queryParams: Params,
	type: 'adults' | 'children',
	defaultValue: number,
): number => {
	return filterOptions[type].find((v) => v === +queryParams[type])
		? +queryParams[type]
		: filterOptions[type].includes(defaultValue)
			? defaultValue
			: Math.min(...filterOptions[type]);
};

export const findNightValue = (filterOptions: OfferFilter, queryParams: Params, type: 'min' | 'max'): number => {
	return filterOptions.defaultNights.find((v) => v === +queryParams[`nights_${type}`])
		? +queryParams[`nights_${type}`]
		: Math[type](...filterOptions.nights[type]);
};

export const setSelectedValueBaseOnParams = (filterOptions: OfferFilter, queryParams: Params): Selected => {
	const children = findParticipantValue(filterOptions, queryParams, 'children', 0);
	const adults = findParticipantValue(filterOptions, queryParams, 'adults', children > 0 ? 0 : 2);

	let dateRangeArr: string[] = [];
	if (queryParams['date_range']) {
		dateRangeArr = queryParams['date_range'].split(',');
	}

	return {
		adults: adults,
		boards: stringToArray(queryParams['boards']),
		children: children,
		conveniences: stringToArray(queryParams['conveniences']),
		kinds: stringToArray(queryParams['kinds']),
		locations: stringToArray(queryParams['locations']),
		nights: {
			max: findNightValue(filterOptions, queryParams, 'max'),
			min: findNightValue(filterOptions, queryParams, 'min'),
		},
		offersDateRange: {
			lower: dateRangeArr.length ? dateRangeArr[0] : null,
			upper: dateRangeArr.length ? dateRangeArr[1] : null,
		},
		ownTransport: !!queryParams['own_transport'],
		roomType: [],
		stops: stringToArray(queryParams['stops']),
		tags: stringToArray(queryParams['tags']),
		search: queryParams['product_name'] || '',
		status: Number.isNaN(+queryParams['status']) ? null : +queryParams['status'],
	};
};

export const createQueryParams = (queryString: string): Params => {
	const queryParamsArray = queryString.slice(1).split('&');
	return queryParamsArray.reduce((acc, param) => {
		const [key, value] = param.split('=');
		(acc as Params)[key] = value;
		return acc;
	}, {});
};

export const createOfferListHeaderArray = (selected: Selected, options: OfferFilter): OfferListHeader[] => {
	return Object.keys(selected)
		.map((key) => {
			const selectedValues = selected[key as keyof Selected];
			const selectedOptions = options[key as keyof OfferFilter];
			const text = TopSearchHeaderConfig.find((v) => v.id === key)?.text;

			switch (key) {
				case OfferListHeaderItemMode.ADULTS:
				case OfferListHeaderItemMode.CHILDREN:
					if (selectedValues) {
						return createParticipantHeader(selectedValues as number, key);
					}
					break;

				case OfferListHeaderItemMode.NIGHTS:
					return createNightsHeader(selectedValues as Nights, key, options, text);
				case OfferListHeaderItemMode.DATE_RANGE:
					return createDateRangeHeader(selectedValues as OfferDateRange, key, text);
				case OfferListHeaderItemMode.STOPS:
					if (selected['ownTransport']) {
						return {
							key: key,
							name: text,
							value: 'Dojazd własny',
							defaultOption: false,
						};
					}
					return createMultiChoiceHeader(
						selectedValues as number[],
						selectedOptions as BaseFilter[],
						key,
						text,
					);
				case OfferListHeaderItemMode.SEARCH:
					if (selectedValues) {
						return {
							key: key,
							name: text,
							value: selectedValues as string,
							defaultOption: false,
						};
					}
					break;
				default:
					if (!(key as TopSearchDropdownItemMode)) {
						return;
					}

					if (MultiChoiceModes.includes(key as TopSearchDropdownItemMode)) {
						return createMultiChoiceHeader(
							selectedValues as number[],
							selectedOptions as BaseFilter[],
							key,
							text,
						);
					}
					break;
			}
		})
		.filter((v) => v)
		.sort((a, b) => customListHeaderSort(a, b));
};

export const createParticipantHeader = (selectedValues: number, key: string): OfferListHeader => ({
	key: key,
	name: key === OfferListHeaderItemMode.ADULTS ? 'Dorośli' : 'Dzieci (0-12 lat)',
	value: selectedValues as number,
	defaultOption: key === OfferListHeaderItemMode.ADULTS, // if default option is true it's not removeable
});

export const createNightsHeader = (
	selectedValues: Nights,
	key: string,
	options: OfferFilter,
	text: string,
): OfferListHeader => {
	const { min: nightsMin, max: nightsMax } = selectedValues as Nights;
	let defaultOption = false;
	if (nightsMin === Math.min(...options.defaultNights) && nightsMax === Math.max(...options.defaultNights)) {
		defaultOption = true;
	}
	if (nightsMin && nightsMax) {
		return {
			key: key,
			name: text,
			value: nightsMin === nightsMax ? nightsMin : `${nightsMin} - ${nightsMax}`,
			defaultOption: defaultOption,
		};
	}
};

export const createDateRangeHeader = (selectedValues: OfferDateRange, key: string, text: string): OfferListHeader => {
	const { lower, upper } = selectedValues;
	if (lower && upper) {
		return {
			key: key,
			name: text,
			value: `${formatOskarDate(new Date(lower))} - ${formatOskarDate(new Date(upper))}`,
			defaultOption: false,
		};
	}
};

export const createMultiChoiceHeader = (
	selectedValues: number[],
	selectedOptions: BaseFilter[],
	key: string,
	text: string,
): OfferListHeader => {
	const multiChoiceValues = selectedOptions.filter((v) => selectedValues.includes(v.id));

	if (multiChoiceValues.length) {
		return {
			key: key,
			name: text,
			value: multiChoiceValues.map((v) => v.name).join(', '),
			defaultOption: false,
		};
	}
};

export const customListHeaderSort = (a: OfferListHeader, b: OfferListHeader): number => {
	const sortingOrder = [
		OfferListHeaderItemMode.ADULTS,
		OfferListHeaderItemMode.CHILDREN,
		OfferListHeaderItemMode.NIGHTS,
		OfferListHeaderItemMode.DATE_RANGE,
	] as string[];
	const indexA = sortingOrder.indexOf(a.key);
	const indexB = sortingOrder.indexOf(b.key);

	if (indexA !== -1 && indexB !== -1) {
		return indexA - indexB;
	}

	if (indexA !== -1) {
		return -1;
	}

	if (indexB !== -1) {
		return 1;
	}

	return a.key.localeCompare(b.key);
};

export const findHeaderValue = (headers: OfferListHeader[], key: string): string | number =>
	headers.find((v) => v.key === key)?.value;

export const getOffsetFromNext = (next: string | null): number | null => {
	const params = new URLSearchParams(next);
	const offset = params.get('offset');
	return Number(offset) ?? null;
};
